import { ReadonlyURLSearchParams } from 'next/navigation'

export function searchParamsToObject(
  searchParams: ReadonlyURLSearchParams
): Record<string, string | string[]> {
  const params = {}
  for (const key of searchParams.keys()) {
    params[key] =
      searchParams.getAll(key).length > 1 ? searchParams.getAll(key) : searchParams.get(key)
  }
  return params
}
