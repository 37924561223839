import type { FC } from 'react'
import { ErrorItem } from '@/core/typings'
import { Text } from '@chakra-ui/react'

interface ErrorHandlerProps {
  errors: ErrorItem[] | null
}

const ErrorHandler: FC<ErrorHandlerProps> = ({ errors }) => {
  if (!errors) {
    return null
  }

  const errorMessages = errors.map((error) => error.message).join(', ')

  return (
    <Text fontSize="lg" fontWeight="bold" color="red.500">
      {errorMessages}
    </Text>
  )
}

export default ErrorHandler
