import React from 'react'
import { Box, BoxProps, Image } from '@chakra-ui/react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

interface ImageCarouselProps extends BoxProps {
  imgSrcs: string[]
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ imgSrcs, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        '.carousel .control-dots .dot': {
          display: imgSrcs.length > 1 ? 'inline-block' : 'none',
          width: 2,
          height: 2,
          borderRadius: '50%',
          backgroundColor: 'primary.200',
          boxShadow: 'none'
        },
        '.carousel .control-dots .dot.selected': {
          backgroundColor: 'primary'
        }
      }}>
      <Carousel infiniteLoop showStatus={false} showThumbs={imgSrcs.length > 1}>
        {imgSrcs.map((imgSrc, index) => (
          <Box
            key={index}
            h="100%"
            w="100%"
            transition="transform .2s ease-in-out"
            _hover={{
              transform: 'scale(1.1)'
            }}>
            <Image src={imgSrc} alt={`Slide ${index}`} objectFit="cover" h="100%" w="100%" />
          </Box>
        ))}
      </Carousel>
    </Box>
  )
}

export default ImageCarousel
