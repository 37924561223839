import { MediaDTO } from '@/core/media/typings'

export const mainMediasFactory = (medias: MediaDTO[]) => {
  const main = medias.filter((m) => m.isMain)
  const defaultImg = '/default-property.png'

  if (main.length) {
    return main.map(({ url, thumb }) => ({ url, thumb }))
  }

  if (medias.length) {
    return [{ url: medias[0].url, thumb: medias[0].thumb }]
  }

  return [{ url: defaultImg, thumb: defaultImg }]
}
