import React from 'react'
import { Box, IconButton, Link, Text, VStack } from '@chakra-ui/react'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6'

interface SocialShareButtonsProps {
  url: string
  title: string
  description?: string
}

const SocialShareButtons: React.FC<SocialShareButtonsProps> = ({
  url,
  title,
  description = ''
}) => {
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
  const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`

  return (
    <VStack spacing={4}>
      <Text fontSize="sm">Comparte en redes sociales</Text>

      <Box>
        <Link href={facebookUrl} isExternal>
          <IconButton
            minW="auto"
            h={8}
            w={8}
            aria-label="Comparte en Facebook"
            icon={<FaFacebookF />}
            colorScheme="facebook"
            mx={2}
          />
        </Link>
        <Link href={twitterUrl} isExternal>
          <IconButton
            minW="auto"
            h={8}
            w={8}
            aria-label="Comparte en X"
            icon={<FaX />}
            colorScheme="black"
            mx={2}
          />
        </Link>
        <Link href={linkedinUrl} isExternal>
          <IconButton
            minW="auto"
            h={8}
            w={8}
            aria-label="Comparte en LinkedIn"
            icon={<FaLinkedinIn />}
            colorScheme="linkedin"
            mx={2}
          />
        </Link>
      </Box>
    </VStack>
  )
}

export default SocialShareButtons
