import React, { FC } from 'react'
import { Avatar, Heading, HStack, Icon, StackProps, Text, VStack } from '@chakra-ui/react'
import { FaMobile } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { BrokerDTO } from '@/core/broker/typings'

interface BrokerContactCardProps extends StackProps {
  broker: BrokerDTO
}

const BrokerContactCard: FC<BrokerContactCardProps> = ({ broker, ...props }) => {
  return (
    <HStack
      align="center"
      spacing={6}
      w="full"
      p={4}
      py={8}
      rounded="md"
      maxW="sm"
      bg="background.surface"
      {...props}>
      <VStack spacing={6} align="flex-start">
        <Heading as="h5" fontSize="3xl">
          Contacto
        </Heading>
        <Heading as="h6" fontSize="lg">
          {broker.name}
        </Heading>

        <HStack spacing={4}>
          <Icon boxSize={6}>
            <FaMobile />
          </Icon>
          <Text flex={1} fontSize="md">
            {broker.phone}
          </Text>
        </HStack>
        <HStack spacing={4}>
          <Icon boxSize={6}>
            <MdEmail />
          </Icon>
          <Text flex={1} fontSize="md">
            {broker.email}
          </Text>
        </HStack>
      </VStack>
      <Avatar src={broker.thumb} h={'80px'} w={'80px'} />
    </HStack>
  )
}

export default BrokerContactCard
