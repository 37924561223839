import React, { FC } from 'react'
import {
  GridItem,
  HStack,
  Icon,
  SimpleGrid,
  StackDivider,
  StackProps,
  Text,
  VStack
} from '@chakra-ui/react'
import ImageCarousel from '@/app/components/common/ImageCarousel'
import { mainMediasFactory } from '@/core/media/utils/main-medias-factory'
import RealEstateListItem from '@/app/(public)/components/RealEstateListItem'
import { FaSquare } from 'react-icons/fa'
import { RealEstateLightDTO } from '@/core/real-estate/typings'

interface RealEstateItemVerticalProps extends StackProps {
  item: RealEstateLightDTO
  onItemClick: (item: RealEstateLightDTO) => void
  noBrokers?: boolean
}

const RealEstateItemVertical: FC<RealEstateItemVerticalProps> = ({
  item,
  onItemClick,
  noBrokers,
  ...props
}) => {
  return (
    <VStack
      borderColor="border"
      borderWidth={1}
      h="full"
      bg="background.default"
      rounded="md"
      overflow="hidden"
      spacing={0}
      {...props}>
      <VStack spacing={0} w="full" divider={<StackDivider />}>
        <ImageCarousel
          // w={{ base: 'full', md: SEARCH_ITEM_SIZE }}
          // h={{ base: 'full', md: SEARCH_ITEM_SIZE }}
          w="full"
          h="full"
          imgSrcs={mainMediasFactory(item.medias).map((m) => m.thumb)}
        />
        <RealEstateListItem
          w="full"
          onClick={() => onItemClick(item)}
          flex={1}
          p={2}
          {...{ item, noBrokers }}
        />
      </VStack>
      {item.highlights.length ? (
        <SimpleGrid bg="background.default" columns={2} w="full" gap={2} py={4} px={6}>
          {item.highlights.map((highlight, index) => (
            <GridItem key={index} flex={1}>
              <HStack w="full">
                <Icon as={FaSquare} color="primary" boxSize={2} />
                <Text fontSize="sm">{highlight}</Text>
              </HStack>
            </GridItem>
          ))}
        </SimpleGrid>
      ) : null}
    </VStack>
  )
}

export default RealEstateItemVertical
