'use client'
import type { FC } from 'react'
import { Text, VStack } from '@chakra-ui/react'
import { RealEstateDTO, RealEstateLightDTO } from '@/core/real-estate/typings'

interface RealEstateAddressProps {
  item: RealEstateLightDTO | RealEstateDTO
}

const RealEstateAddress: FC<RealEstateAddressProps> = ({ item }) => {
  return (
    <VStack w="full" align="flex-start">
      <Text className="address-street" fontSize="xl" fontWeight="bold" color="primary">
        {item.name}
      </Text>
      <Text className="address-neighborhood" fontSize="md" color="gray.700">
        {item.address.street}, {item.address.neighborhood}
      </Text>
    </VStack>
  )
}

export default RealEstateAddress
